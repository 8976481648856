<template>
  <div>
    <keep-alive v-if="$route.meta.keepAlive">
      <router-view></router-view>
    </keep-alive>
    <router-view v-else />
    <van-tabbar class="text-sm" style="z-index: 2;" v-model="active" route safe-area-inset-bottom>
      <van-tabbar-item name="travel" class="ziconfont" icon-prefix="icon" icon="daohang" replace to="/">出行
      </van-tabbar-item>
      <van-tabbar-item name="route" class="ziconfont" icon-prefix="icon" icon="dingdan" replace to="/plan">订单
      </van-tabbar-item>
      <van-tabbar-item name="setting" class="ziconfont" icon-prefix="icon" icon="wode" replace to="/setting">我的
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: "travel"
    };
  },
  methods: {}
};
</script>

<style lang="less" scoped>
.ziconfont {
  font-family: "iconfont" !important;
}
</style>
